import cn from '@/utils/style/cn.ts'

export type LoadingSpinnerProps = {
  color?: 'gray' | 'white' | 'black'
  size?: 16 | 24 | 36
  className?: string
}

const colorMap = {
  gray: 'text-gray-600',
  white: 'text-white',
  black: 'text-gray-900',
} as const

/**
 * Simple loading spinner component.
 */
export default function LoadingSpinner({
  color = 'black',
  size = 24,
  className,
}: LoadingSpinnerProps) {
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={cn(className, colorMap[color])}
      width={`${size}px`}
      height={`${size}px`}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        r="35"
        strokeDasharray="166 60"
        transform="rotate(246.466 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  )
}
