import isBrowser from './isBrowser.ts'

/**
 * Get the base URL for the current environment.
 */
export default function getBaseURL() {
  if (isBrowser()) {
    return window.location.origin
  }

  const gitBranch = process.env.GIT_BRANCH ?? process.env.VERCEL_GIT_COMMIT_REF

  if (!gitBranch) {
    return 'http://localhost:3000'
  }

  if (gitBranch === 'production') {
    return 'https://www.sirvatus.com'
  }

  if (gitBranch === 'staging') {
    return 'https://staging.sirvatus.com'
  }

  return `https://${gitBranch
    .toLowerCase()
    .replace(/\//g, '-')}-preview.sirvatus.com`
}
