import type { AppRouter } from '@/server/appRouter.ts'
import getBaseURL from '@/utils/common/getBaseURL.ts'
import type { CreateTRPCClientOptions } from '@trpc/client'
import { httpBatchLink, httpLink, splitLink } from '@trpc/client'
import superjson from '../superjson/superjson.ts'

const url = `${getBaseURL()}/api/trpc`

const trpcClientConfig: CreateTRPCClientOptions<AppRouter> = {
  links: [
    splitLink({
      condition(op) {
        return op.context.skipBatch === true
      },
      true: httpLink({ url, transformer: superjson }),
      false: httpBatchLink({ url, transformer: superjson }),
    }),
  ],
}

export default trpcClientConfig
