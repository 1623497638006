'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import {
  IntercomProvider as ReactUseIntercomProvider,
  useIntercom,
} from 'react-use-intercom'

const API_BASE = 'https://api-iam.intercom.io'
const ENABLED_PATHS = ['/app']

export function IntercomProvider({ children }: React.PropsWithChildren) {
  return (
    <ReactUseIntercomProvider
      apiBase={API_BASE}
      appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!}
    >
      {children}
      <IntercomProviderNavigationEvents />
    </ReactUseIntercomProvider>
  )
}

function IntercomProviderNavigationEvents() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { shutdown } = useIntercom()

  useEffect(() => {
    const url = `${pathname}?${searchParams}`
    if (typeof window !== 'undefined') {
      if (!ENABLED_PATHS.some((path) => url.startsWith(path))) {
        shutdown()
        return
      }
    }
  }, [pathname, searchParams])

  return null
}
